import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import {withAuthenticator} from "@aws-amplify/ui-react"
const Navigation =  ({signOut,setVantaEffect}) => {
    console.log("'dedede")
    setVantaEffect(1)
return (
<>
  <Navbar bg="dark" variant="dark">
    <Container>
    <Navbar.Brand href="#home"></Navbar.Brand>
    <Nav className="me-auto">
      <Nav.Link href="#designs">Solutions</Nav.Link>
      <Nav.Link href="#work experience">Work</Nav.Link>
      <Nav.Link href="#certification">Certifications</Nav.Link>
    </Nav>
    <Button variant="light" className="justify-content-end" onClick={signOut}>Log out</Button>
    </Container>
  </Navbar>
  <br />
  
</>
)
}

export default withAuthenticator(Navigation)