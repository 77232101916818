import logo from './logo.svg';
import './App.css';
import { Auth } from 'aws-amplify';
import {withAuthenticator} from "@aws-amplify/ui-react"
import Navigation from './components/Navbar';
import React, { useState, useEffect, useRef } from "react";
import NET from 'vanta/dist/vanta.net.min'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';



function App({signOut, user}) {
  const [vantaEffect, setVantaEffect] = useState(0);
  const [login, setLogin] = useState(false);
  const vantaRef = useRef(null);
  useEffect(() => {
    console.log("here")
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          el: vantaRef.current,
          
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 800.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          backgroundColor: 0x0,
          color: 0xffc63f,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  console.log("user",user)
  // Auth.currentSession()
  //   .then(data => {
  //       let idToken = data.getIdToken();
  //       console.dir(idToken);
  //       let email = idToken.payload.email;
  //       console.log(email);
  //   })
  //   .catch(err => console.log(err));
  return (
    <>
   
    <div ref={vantaRef}>
    {/* <Navigation signOut={signOut}></Navigation>
    <div className="App">
      hello {user.username}
      <button onClick={signOut}>Sign out</button>
    </div> */}
    {/* <button onClick={()=>{setLogin(true)}}> login </button> */}
  
    </div>
     <Navigation setVantaEffect={setVantaEffect}></Navigation>
    
    </>
  );
}

// export default withAuthenticator(App);
export default App;
